<template>
    <div class="dialog_box">
        <el-dialog title="新增合同" :visible.sync="shows" :close-on-click-modal="false"  :close-on-press-escape="false" @closed="onclosed">
            <div class="form_box">
                <div class="form_flex">
                   
                    <div class="form_item">
                        <div class="form_title"><span>*</span>姓名:</div>
                        <div class="form_input2">
                            <el-select  filterable :filter-method="do_search_user" v-model="form.user_id" placeholder="请选择人员_姓名" @change="change_user">
								<el-option v-for="item in user_list" :key="item.id" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
                        </div>
                    </div>
                    <div class="form_flex2" style="justify-content: space-between;">
					<div class="form_flex_text">
						<div class="form_flex_text_left">
							部门：
						</div>
						<span>{{user_info.depart_title}}</span>
					</div>

					<div class="form_flex_text" style="width: 300px;">
						<div class="form_flex_text_left">
							岗位：
						</div>
						<span>{{user_info.level6}}</span>
					</div>
				</div>
                    <div class="form_item">
                        <div class="form_title"><span>*</span>签订时间:</div>
                        <div class="form_input3">
                            <el-date-picker v-model="form.s_time" type="date" placeholder="请选择签订时间"  format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title"><span>*</span>到期时间:</div>
                        <div class="form_input3">
                            <el-date-picker v-model="form.e_time" type="date" placeholder="请选择到期时间"  format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="form_item2">
                        <div class="form_title"><span>*</span>合同:</div>
                        <div class="form_input4">
                             <div class="btns" @click="handle_up">上传附件</div>
														 		<upload_tip />
                             <div class="file_flex" v-for="(item,index) in pdf_list" :key="index">
                                <div class="files_text">{{ item.name }}</div>
                                <div class="files_clear">
                                    <img src="../../assets/img/employee/clear.png" alt="" @click="handle_clear(index)">
                                </div>
                             </div>
                        </div>
                    </div>
                  
                </div>
                <div class="form_btns">
                    <div class="btns1" @click="handle_ends">保存</div>
                    <div class="btns2" @click="handle_close">取消</div>
                </div>
                
            </div>
        </el-dialog>
        <upload_img ref="upload_img"></upload_img>



    </div>
</template>
<script>
import upload_img from '../upload/upload.vue'

export default {
    name: "Customer_Information_form",
    components:{
        upload_img,
    },
    props: {

    },
    data() {
        return {
            shows: false,
            form:{
                id:'',
                user_id:'',//用户id
                name:'',//姓名
                id_card:'',//身份证号
                mobile:'',//手机号
                id_type:'',//证件类型
                pdf:'',//合同文件
                s_time:'',//合同开始时间
                e_time:'',//合同结束时间
            },
            user_info:{},
            pdf_list:[],
            user_list:[],

        };
    },
    methods: {
        onclosed(){
            this.form={
                id:'',
                user_id:'',//用户id
                name:'',//姓名
                id_card:'',//身份证号
                mobile:'',//手机号
                id_type:'',//证件类型
                pdf:'',//合同文件
                s_time:'',//合同开始时间
                e_time:'',//合同结束时间
            }
            this.pdf_list=[]
            this.user_info={}
        },
        //打开
        handle_open(item) {
            if(item){
                // 
                for (let key in this.form) {
                    this.form[key]=item[key] 
                }
                this.pdf_list=JSON.parse(item.pdf)
                this.get_user_list(item.name)

            }
            this.shows = true
        },
        //关闭    
        handle_close() {
            this.shows = false

        },
        handle_up(){
            this.$refs.upload_img.uploadFile()
        },
        upload_url(url){
            console.log(url)
            this.pdf_list.push(url)
        },
        //确认
        handle_ends() {
            console.log(this.form)
            if(!this.form.name){
                alertErr('请选择人员')
                return
            }
            if(!this.form.s_time){
                alertErr('请选择签订时间')
                return
            }
            if(!this.form.e_time){
                alertErr('请选择到期时间')
                return
            }
            if(this.pdf_list.length==0){
                alertErr('请上传合同文件')
                return
            }
            this.form.pdf=JSON.stringify(this.pdf_list)
            
            this.loading = true
            this.$api("editUserContract", {
                ...this.form
            }, "post").then((res) => {
                this.loading = false
                if (res.code == 200) {
                    this.shows = false
                    this.$emit("handle_get_list");
                } else {
                    alertErr(res.msg)
                }
            });
        },
        get_user_list(name){
            this.$api("getUserList", {
                page: 1,
                limit: 200,
                keyword:name||""
            }, "get").then((res) => {
                this.loading = false
                if (res.code == 200) {
                    this.count = res.data.count
                    this.user_list = res.data.list
                    if(name){
                        this.user_list.forEach((e)=>{
                            if(e.name==name){
                                this.user_info=e
                            }
                        })
                    }
                } else {
                    alertErr(res.msg)
                }
            });
        },
        do_search_user(e){
            this.$api("getUserList", {
                page: 1,
                limit: 200,
                keyword:e
            }, "get").then((res) => {
                this.loading = false
                if (res.code == 200) {
                    this.count = res.data.count
                    this.user_list = res.data.list
                } else {
                    alertErr(res.msg)
                }
            });
        },
        change_user(e){
            console.log(e)
            this.user_list.forEach((v)=>{
                if(v.id==e){
                    this.form.name=v.name
                    this.form.id_card=v.id_card
                    this.form.mobile=v.mobile
                    this.form.id_type=v.id_type
                    this.user_info=v
                }
            })
        },
        handle_clear(i){
            this.pdf_list.splice(i,1)
        },
    },
    computed: {
    },

    watch: {
        
    },


    created() {
        this.get_user_list()

    },


};
</script>

<style scoped lang="less">
.dialog_box {
    /deep/.el-dialog {
        width: 760px;
    }

    /deep/.el-dialog__header {
        background: #F2F5FA !important;
    }

    .form_box {
       
        .form_flex{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            border-bottom: 1px solid #EEEEEE ;
            padding-bottom: 23px;
            .form_item{
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 17px;
                .form_title{
                    width: 130px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    text-align: right;
                    span{
                        color: #FC5F03;
                        margin-right: 5px;
                    }

                }
                .form_input1{
                    width: 100%;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    border: 1px solid #D4D4D4;
                    margin-left: 11px;
                    input{
                        width: 100%;
                        height: 40px;
                        padding: 0 10px;                      
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;

                    }
                }
                .form_input2{
                    width: 100%;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    margin-left: 11px;
                    display: flex;
                    align-items: center;
                    /deep/.el-select{
                        width: 100%;
                    }
                }
                .form_input3{
                    width: 100%;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    margin-left: 11px;
                    display: flex;
                    align-items: center;
                    /deep/.el-date-editor {
                        width: 100%;
                    }
                }
                .form_input4{
                    width: 100%;
                    margin-left: 11px;

                    .btns{
                        width: 91px;
                        height: 31px;
                        background: linear-gradient( 90deg, #157DE9 0%, #3697FD 100%);
                        border-radius: 4px 4px 4px 4px;
                        text-align: center;
                        line-height: 31px;
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFFFFF;

                    }
                    .file_flex{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 20px;
                        .files_text{
                            font-family: Microsoft YaHei, Microsoft YaHei;
                            font-weight: 400;
                            font-size: 14px;
                            color: #333333;
                        }
                    }
                 
                  
                  
                }
            }
            .form_flex2 {
                display: flex;
                align-items: center;
                // justify-content: space-around;
                margin-bottom: 16px;

                .form_flex_text {

                    .form_flex_text_left {
                        width: 120px;
                        text-align: right;
                        // /deep/
                    }

                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    font-size: 14px;
                    color: #4A4A4A;

                    span {
                        margin-left: 15px;
                        display: flex;
                        align-items: center;

                        img {
                            width: 12px;
                            margin-left: 60px;
                            height: 12px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .form_item2{
                width: 100%;
                display: flex;
                margin-bottom: 17px;
                .form_title{
                    width: 130px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    text-align: right;
                    span{
                        color: #FC5F03;
                        margin-right: 5px;
                    }

                }
                 
                .form_input4{
                    width: 100%;
                    margin-left: 11px;

                    .btns{
                        width: 91px;
                        height: 31px;
                        background: linear-gradient( 90deg, #157DE9 0%, #3697FD 100%);
                        border-radius: 4px 4px 4px 4px;
                        text-align: center;
                        line-height: 31px;
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFFFFF;
                        cursor: pointer;
                    }
                    .file_flex{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 20px;
                        .files_text{
                            font-family: Microsoft YaHei, Microsoft YaHei;
                            font-weight: 400;
                            font-size: 14px;
                            color: #333333;
                        }
                        .files_clear{
                            cursor: pointer;
                            img{
                                width: 15px;
                                height: 15px;
                            }

                        }
                    }
                 
                  
                  
                }
            }
        }
        .form_btns{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 18px;
            .btns1{
                width: 91px;
                height: 38px;
                background: #2373C8;
                border-radius: 4px 4px 4px 4px;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                text-align: center;
                line-height: 38px;
                cursor: pointer;
            }
            .btns2{
                width: 91px;
                height: 38px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #B8B8B8; 
                text-align: center;
                line-height: 38px;
                cursor: pointer;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #6A6A6A;
                margin-left: 22px;
            }
        }
    }
}
</style>